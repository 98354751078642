import { AppLocale } from '@/lib/l18n';
import React from 'react';
import FlagChinese from './FlagSvg/FlagChinese';
import FlagEnglish from './FlagSvg/FlagEnglish';
import FlagGerman from './FlagSvg/FlagGerman';
import FlagJapanese from './FlagSvg/FlagJapanese';
import FlagSpanish from './FlagSvg/FlagSpanish';

type Language<T extends AppLocale> = {
  code: T;
  name: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const languageOptions: { [P in AppLocale]: Language<P> } = {
  en: {
    code: 'en',
    name: 'English',
    Icon: FlagEnglish,
  },
  de: {
    code: 'de',
    name: 'Deutsch',
    Icon: FlagGerman,
  },
  es: {
    code: 'es',
    name: 'Español',
    Icon: FlagSpanish,
  },
  ja: {
    code: 'ja',
    name: '日本語',
    Icon: FlagJapanese,
  },
  zh: {
    code: 'zh',
    name: '中文。',
    Icon: FlagChinese,
  },
};
