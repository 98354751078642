import { sanitiseAnything, SanitisedElement } from '@/lib/sanitise/sanitiseElements';
import { Typename } from '@liquorice/allsorts-craftcms-nextjs';
import { GlobalsFragment } from '__generated__/graphql';

export type RawGlobals = GlobalsFragment;

export type GlobalTypename = Typename<RawGlobals>;

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

export type SanitisedGlobal<T extends GlobalTypename = GlobalTypename> = SanitisedElement<T>;
export type AppGlobal<T extends GlobalTypename = GlobalTypename> = SanitisedElement<T>;

// ----------------------------------------------------------------------------------------------------

/**
 * Create a {@link AppGlobal} consumer React Component by providing
 * the `__typename` of `GlobalType` as T
 */
export const createGlobal = <
  T extends GlobalTypename,
  P = {
    /*  */
  },
  R = JSX.Element
>(
  fn: (props: Partial<AppGlobal<T>> & P) => R
) => fn;

export const sanitiseGlobals = (maybeGlobals: MaybeArrayOf<GlobalsFragment>) => {
  return sanitiseAnything.many(maybeGlobals) as SanitisedGlobal[];
};

export const parseSanitisedGlobals = (sanitisedGlobals: SanitisedGlobal[]) => {
  return {
    socials: sanitisedGlobals.find(({ __typename }) => __typename === 'social_GlobalSet') as
      | AppGlobal<'social_GlobalSet'>
      | undefined,
    acknowledgementOfCountry: sanitisedGlobals.find(
      ({ __typename }) => __typename === 'acknowledgementOfCountry_GlobalSet'
    ) as AppGlobal<'acknowledgementOfCountry_GlobalSet'> | undefined,
    copyright: sanitisedGlobals.find(({ __typename }) => __typename === 'copyright_GlobalSet') as
      | AppGlobal<'copyright_GlobalSet'>
      | undefined,
    mediaRelease: sanitisedGlobals.find(
      ({ __typename }) => __typename === 'mediaRelease_GlobalSet'
    ) as AppGlobal<'mediaRelease_GlobalSet'> | undefined,
  };
};

export const parseGlobals = (maybeGlobals: MaybeArrayOf<GlobalsFragment>) => {
  return parseSanitisedGlobals(sanitiseGlobals(maybeGlobals));
};

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGlobalType = <T extends GlobalTypename>(x: any, typename: T): x is AppGlobal<T> => {
  return !!x && x.__typename === typename;
};
