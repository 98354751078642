import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <mask
      id="a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <circle cx={10} cy={10} r={10} fill="#D9D9D9" />
    </mask>
    <g mask="url(#a)">
      <path fill="#FC0" d="M0 13.332h20V20H0v-6.668Z" />
      <path fill="#000001" d="M0 0h20v6.668H0V0Z" />
      <path fill="red" d="M0 6.668h20v6.664H0V6.668Z" />
    </g>
  </svg>
);
export default SvgComponent;
