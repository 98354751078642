'use client';
import { isEntry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import parseEntryLocales from './parseEntryLocales';

export default function useViewLocales() {
  const view = useView();
  if (!isEntry(view)) return null;
  const localized = parseEntryLocales(maybeGet(view, 'localized'), view);

  return localized;
}
