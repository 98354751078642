import Txt, { TxtProps } from '../ui/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.css';
import { fmtFullName } from '@/lib/utils/format';
import Btn from '../ui/Btn';

export type CardTitleProps<D extends React.ElementType = 'h2'> = TxtProps<
  D,
  {
    enableLinkWrap?: boolean;
  }
>;

export const CardTitle = <D extends React.ElementType>({
  children,
  enableLinkWrap = false,
  ...props
}: CardTitleProps<D>) => {
  const { honorific, firstName, lastName, titleComponent, title, href } = useCard();

  const honorificTitle = fmtFullName(honorific, firstName, lastName);
  const content = honorificTitle || title;

  if (enableLinkWrap && href) {
    return (
      <Btn variant="text" as="a" href={href}>
        <Txt as={titleComponent || 'h2'} variant="h4" {...mergePropsClassName(props, style.title)}>
          {children || content}
        </Txt>
      </Btn>
    );
  }

  return (
    <Txt as={titleComponent || 'h2'} variant="h4" {...mergePropsClassName(props, style.title)}>
      {children || content}
    </Txt>
  );
};
