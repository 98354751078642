import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import Btn from '../ui/Btn';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardEmailProps<D extends React.ElementType = 'p'> = TxtProps<
  D,
  {
    enableLinkWrap?: boolean;
  }
>;

export const CardEmail = <D extends React.ElementType>({
  children,
  enableLinkWrap = false,
  ...props
}: CardEmailProps<D>) => {
  const t = useTranslations('common');
  const { firstName, email } = useCard();
  const content = children ?? firstName;
  if (!content || !email) return null;

  if (enableLinkWrap) {
    return (
      <Btn
        variant="text"
        color="neutral"
        as="a"
        href={'mailto:' + email}
        {...mergePropsClassName(props, style.email)}>
        {t('email') + ' ' + content}
      </Btn>
    );
  }

  return (
    <Txt as="span" color="neutralDark" {...mergePropsClassName(props, style.email)}>
      {t('email') + ' ' + content}
    </Txt>
  );
};
