import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <mask
      id="a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <circle cx={10} cy={10} r={10} fill="#D9D9D9" />
    </mask>
    <g mask="url(#a)">
      <path fill="#EE1C25" d="M0 0h20v20H0V0Z" />
      <g clipPath="url(#b)">
        <mask
          id="c"
          width={20}
          height={21}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}>
          <path fill="#fff" d="M.001 0h19.998v20.001H.001V0Z" />
        </mask>
        <g mask="url(#c)">
          <path fill="#fff" fillRule="evenodd" d="M-5 0h30v20.001H-5V0Z" clipRule="evenodd" />
          <path
            fill="#BC002D"
            d="M10 16.219a6.217 6.217 0 1 0 0-12.434 6.217 6.217 0 0 0 0 12.434Z"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
