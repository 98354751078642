import { gql } from '__generated__';

export const COMMON_LINK_QUERY = gql(`
    query commonLinks(
      $site: [String] = "default"
      $preferSites: [QueryArgument] = ["default"]
      $unique: Boolean
    ) {
    home: entry(
      site: $site
      preferSites: $preferSites
      unique: $unique
      uri: "__home__"
    ) {
      id
      uri
      title
    }
    search: entry(
      site: $site
      preferSites: $preferSites
      unique: $unique
      section: "search"
    ) {
      id
      uri
      title
    }
    articleIndex: entry(
      site: $site
      preferSites: $preferSites
      unique: $unique
      section: "articleIndex"
    ) {
      id
      uri
      title
    }
    areaIndex: entry(
      site: $site
      preferSites: $preferSites
      unique: $unique
      section: "areaIndex"
    ) {
      id
      uri
      title
    }
    profileIndex: entry(
      site: $site
      preferSites: $preferSites
      unique: $unique
      section: "profileIndex"
    ) {
      id
      uri
      title
    }
  }
`);
