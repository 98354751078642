'use client';

import { parseCommonLink } from '@/lib/parsers/common';
import { isEntry } from '@/lib/parsers/entries';
import { useAppLink, useView } from '@/lib/store/hooks';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import CtaButton from '../CtaButton';
import Box from '../ui/Box';

export default function Breadcrumbs() {
  const page = useView();

  const home = useAppLink('home');
  const articleIndex = useAppLink('articleIndex');
  const areaIndex = useAppLink('areaIndex');
  const profileIndex = useAppLink('profileIndex');

  const maybeParent = maybeGet(page, 'parent');
  const parent = maybeParent ? parseCommonLink(maybeParent) : home;
  let ancestors = parent ? [parent] : [];

  switch (true) {
    case isEntry(page, 'article'):
      if (articleIndex) ancestors = [articleIndex];
      break;
    case isEntry(page, 'sector'):
      if (areaIndex) ancestors = [areaIndex];
      break;
    case isEntry(page, 'service'):
      if (areaIndex) ancestors = [areaIndex];
      break;
    case isEntry(page, 'product'):
      if (areaIndex) ancestors = [areaIndex];
      break;
    case isEntry(page, 'profile'):
      if (profileIndex) ancestors = [profileIndex];
      break;
  }

  return (
    <Box>
      {ancestors?.map((v) => (
        <CtaButton
          key={v.id}
          href={v.uri ?? ''}
          endIcon={undefined}
          StartIconProps={{ name: 'arrowLeft', cx: { color: 'accent' } }}>
          {v.title}
        </CtaButton>
      ))}
    </Box>
  );
}
