'use client';

import { locales } from '@/lib/l18n';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslations } from 'next-intl';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import Txt from '../ui/Txt';
import { languageOptions } from './languageOptions';
import * as style from './LanguageSelector.css';
import useViewLocales from './useViewLocales';

export const { Link, useRouter } = createSharedPathnamesNavigation({
  locales,
});

export type LanguageSelectorListProps = {
  onClose?: () => void;
};

const LanguageSelectorList = ({ onClose }: LanguageSelectorListProps) => {
  const t = useTranslations('language');
  const locales = useViewLocales()?.locales || [];
  if (locales.length < 2) return null;

  return (
    <Box className={style.list} rounded>
      <Txt className={style.listHeading} bold>
        {t('selectLanguage')}
      </Txt>
      <Divider className={style.listDivider} />

      {locales.map((item) => {
        const language = languageOptions[item.locale];
        const isCurrent = !!item.current;

        return (
          <ListItemButton
            component={Link}
            key={item.locale}
            selected={isCurrent}
            href={item.uri}
            hrefLang={item.locale}
            locale={item.locale}
            className={style.listItem}
            onClick={onClose}>
            <ListItemIcon>
              <language.Icon className={style.listItemIcon} />
            </ListItemIcon>
            <ListItemText className={style.listItemLabel}>{language.name}</ListItemText>
          </ListItemButton>
        );
      })}
    </Box>
  );
};

export default LanguageSelectorList;
