'use client';

import { useView } from '@/lib/store/hooks';
import { PageHeaderProps } from '.';
import Txt, { TxtProps } from '../ui/Txt';

type PageTitleProps = TxtProps<'h1'> & Partial<PageHeaderProps>;

export default function PageTitle({ children, ...props }: PageTitleProps) {
  const page = useView();
  const title = page?.pageTitle ?? page?.title ?? '';

  return (
    <Txt as="h1" variant="h1" cx={{ mB: 'none' }} {...props}>
      {children || title}
    </Txt>
  );
}
