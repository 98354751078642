import { I18N_DEFAULT_LOCALE } from '@/lib/constants';

/**
 * This module provides utilities for handling internationalization (i18n) in the application.
 * @todo support multiple locales
 * @todo align with Craft's i18n
 */

// Importing default messages from a JSON file
import defaultMessages from '@/messages/en.json';

/**
 * The default locale for the application, which is either
 * the value of I18N_DEFAULT_LOCALE or 'en' if I18N_DEFAULT_LOCALE is not defined
 */
export const localeDefault = I18N_DEFAULT_LOCALE || 'en';

/**
 * This function creates translations for the provided locales.
 * @param partials - An object where each key is a locale and the value is an object of message keys and their translations.
 * @returns An object where each key is a locale and the value is an object of message keys and their translations, merged with the default messages.
 */
const createTranslations = <T extends Record<string, RecursivePartial<Messages>>>(partials: T) => {
  const result: { [P in keyof T]: Messages } = {} as { [P in keyof T]: Messages };
  for (const key in partials) {
    if (partials.hasOwnProperty(key)) {
      const maybeStrings = partials[key];
      result[key] = { ...maybeStrings, ...defaultMessages };
    }
  }
  return result;
};

/**
 * The type for the messages in the application
 */
const appStrings = createTranslations({
  [localeDefault]: defaultMessages,
  ja: defaultMessages,
  es: defaultMessages,
  de: defaultMessages,
  zh: defaultMessages,
});

/**
 * The type for the locales in the application
 */
export type AppLocale = keyof typeof appStrings;

/**
 * An array of all the locales in the application
 * @type {AppLocale[]}
 */
export const locales = Object.keys(appStrings) as AppLocale[];

/**
 * This function checks if a string is a valid locale in the application.
 * @param x - The string to check.
 * @returns True if the string is a valid locale, false otherwise.
 */
export const isLocale = (x?: string): x is AppLocale => {
  return !!x && x in appStrings;
};

/**
 * This function gets the messages for a specific locale.
 * @param locale - The locale to get the messages for. If not provided or not a valid locale, the messages for the default locale are returned.
 * @returns The messages for the specified locale, or the messages for the default locale if the specified locale is not valid.
 */
export const getMessages = (locale?: AppLocale | string): Messages => {
  return isLocale(locale) ? appStrings[locale] : appStrings[localeDefault];
};
