import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <mask
      id="a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <circle cx={10} cy={10} r={10} fill="#D9D9D9" />
    </mask>
    <g clipPath="url(#b)" mask="url(#a)">
      <path fill="#AA151B" d="M0 0h20v20H0V0Z" />
      <path fill="#F1BF00" d="M0 5h20v10H0V5Z" />
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
