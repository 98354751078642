import Image, { ImageProps } from '@/components/Image';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.css';
import { fmtFullName } from '@/lib/utils/format';

export type CardImageProps = ImageProps;

export const CardImage = ({ ShimProps = {}, ...props }: CardImageProps) => {
  const { honorific, firstName, lastName, image } = useCard();

  const title = fmtFullName(honorific, firstName, lastName);

  return (
    image && (
      <Image
        alt={title ?? ''}
        {...mergePropsClassName(
          {
            transform: 'landscapeLgCrop',
            ratio: 'landscapeWide',
            ...image,
            ...props,
          },
          style.image
        )}
        ShimProps={mergePropsClassName(ShimProps, style.imageWrap)}
      />
    )
  );
};
