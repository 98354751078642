import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <mask
      id="a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <circle cx={10} cy={10} r={10} fill="#D9D9D9" />
    </mask>
    <g mask="url(#a)">
      <path fill="#EE1C25" d="M0 0h20v20H0V0Z" />
      <g clipPath="url(#b)">
        <path fill="#012169" d="M0 0h20v20H0V0Z" />
        <path
          fill="#fff"
          d="M20 0v2.5L12.578 10 20 17.305V20h-2.617l-7.461-7.344L2.656 20H0v-2.656l7.266-7.305L0 2.891V0h2.422l7.5 7.344L17.187 0H20Z"
        />
        <path
          fill="#C8102E"
          d="m7.188 12.656.43 1.328L1.64 20H0v-.117l7.188-7.227Zm4.843-.469 2.11.313L20 18.242V20l-7.969-7.813ZM20 0l-7.5 7.656-.156-1.718L18.204 0H20ZM0 .04l7.54 7.382-2.306-.313L0 1.914V.04Z"
        />
        <path fill="#fff" d="M6.875 0v20h6.25V0h-6.25ZM0 6.875v6.25h20v-6.25H0Z" />
        <path fill="#C8102E" d="M0 8.125v3.75h20v-3.75H0ZM8.125 0v20h3.75V0h-3.75Z" />
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
