'use client';

import { GLOBALS_QUERY } from '@/gql/queries/globalQueries.gql';
import { CommonLinkId, CommonLinks } from '@/lib/parsers/common';
import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { View } from '../getters/getEntryByUri';
import { parseGlobals } from '../parsers/globals/globals';
import type { AppGlobals } from '../parsers/globals/globalsTypes';
import { appContext } from './context';

export const useView = (): View | null => {
  const { view } = React.useContext(appContext) ?? {};
  return view || null;
};

export const useAppLink = <T extends CommonLinkId>(typeId?: T): CommonLinks[T] | undefined => {
  const { commonLinks } = React.useContext(appContext) ?? {};

  return commonLinks && typeId ? commonLinks[typeId] : undefined;
};

export const useGlobals = () => {
  const { data: globalsData } = useQuery(GLOBALS_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const [globals, setGlobals] = React.useState<AppGlobals>({});

  useEffect(() => {
    globalsData && setGlobals(parseGlobals(globalsData.globalSets) as AppGlobals);
  }, [globalsData]);

  return globals;
};

/* export const useViewBlocks = () => {
  const { view } = React.useContext(appContext) ?? {};

  const blocks = maybeGet(view, 'blocks') ?? [];

  const parsedBlocks = parseSanitisedBlocks(blocks as SanitisedBlock[]);

  return filterBlocks(parsedBlocks);
}; */
