import { gql } from '__generated__';

/**
 * Retrieve all global sets or a specific global set by handle
 */
export const GLOBALS_QUERY = gql(`
  query globals(
    $handle: [String]
    $site: [String] = "default",
    $preferSites: [QueryArgument] = ["default"],
    $unique: Boolean,
    ) {
    globalSets(
      handle: $handle
      site: $site,
      preferSites: $preferSites,
      unique: $unique
    ) {
      ...globals
    }
  }
`);
