import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <mask
      id="a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <circle cx={10} cy={10} r={10} fill="#D9D9D9" />
    </mask>
    <g mask="url(#a)">
      <path fill="#EE1C25" d="M0 0h20v20H0V0Z" />
      <path
        fill="#FF0"
        d="m10.5 8.375-5.1 3.3 2.1-5.4 1.8 5.4-4.8-3.3h6ZM11.741 5.566l1.817.89-1.902.327 1.233-1.44-.118 1.936-1.03-1.713ZM14.057 7.33l1.329 1.528-1.88-.44 1.697-.847-.864 1.74-.282-1.98ZM14.476 10.233l.589 1.939-1.538-1.17 1.896-.08-1.499 1.235.552-1.924ZM12.89 12.308l-.204 2.013-.966-1.67 1.779.656-1.858.56 1.25-1.56Z"
      />
    </g>
  </svg>
);
export default SvgComponent;
