import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Btn from '../ui/Btn';
import { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';
import { useTranslations } from 'next-intl';

export type CardLinkedinProps<D extends React.ElementType = 'h2'> = TxtProps<D>;

export const CardLinkedin = <D extends React.ElementType>({
  children,
  ...props
}: CardLinkedinProps<D>) => {
  const { linkedinUrl } = useCard();
  const t = useTranslations('social');

  if (!linkedinUrl) return;

  return (
    <Btn
      href={linkedinUrl}
      variant="text"
      StartIconProps={{ name: 'linkedin', cx: { color: 'accent' } }}
      {...mergePropsClassName(props, style.linkedin)}>
      {children || t('linkedin')}
    </Btn>
  );
};
