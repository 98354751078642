import { camelize } from '@/lib/utils/format';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { SvgIconTypeMap } from '@mui/material';
import Icon, { IconNames, IconProps, icons } from '../Icon';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardIconProps<D extends React.ElementType = SvgIconTypeMap['defaultComponent']> = Omit<
  IconProps<D>,
  'name'
>;

export const CardIcon = <D extends React.ElementType>({ ...props }: CardIconProps<D>) => {
  const { title } = useCard();

  const iconTitle = title?.toLowerCase() ?? '';

  // Replace ampersand with 'and' and remove non-alphanumeric characters
  const checkForAmpersand = iconTitle.replace(/&/g, 'and');
  const removeNonAlpaNumeric = checkForAmpersand.replace(/\W/g, ' ');

  const name = camelize(removeNonAlpaNumeric) as IconNames;

  if (name in icons) return <Icon name={name} {...mergePropsClassName(props, style.icon)} />;

  return null;
};
