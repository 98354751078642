'use client';

import { COMMON_LINK_QUERY } from '@/gql/queries/commonLink.gql';
import { CommonLinks, parseCommonLinks } from '@/lib/parsers/common';
import { AppContext, AppContextProviderInner } from '@/lib/store/context/createAppContext';
import { useQuery } from '@apollo/client';

import React, { useEffect } from 'react';

type AppContextProviderProps = React.PropsWithChildren<{
  value?: Partial<AppContext>;
}>;

const AppContextProvider = ({ value: partialValue, children }: AppContextProviderProps) => {
  const [commonLinks, setCommonLinks] = React.useState<CommonLinks | undefined>(undefined);

  const { data: commonLinksData } = useQuery(COMMON_LINK_QUERY);

  useEffect(() => {
    commonLinksData && setCommonLinks(parseCommonLinks(commonLinksData) as CommonLinks);
  }, [commonLinksData]);

  const value: AppContext = {
    view: null,
    commonLinks,
    ...partialValue,
  };

  return <AppContextProviderInner value={value}>{children}</AppContextProviderInner>;
};

export default AppContextProvider;
