import Txt, { TxtProps } from '../ui/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.css';

export type CardDescriptionProps<D extends React.ElementType = 'span'> = TxtProps<D>;

export const CardDescription = <D extends React.ElementType>({
  children,
  ...props
}: CardDescriptionProps<D>) => {
  const { description } = useCard();

  return (
    <Txt as="span" variant="body" html {...mergePropsClassName(props, style.description)}>
      {children || description}
    </Txt>
  );
};
