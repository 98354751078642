'use client';

import { Menu } from '@mui/material';
import React from 'react';
import Box from '../ui/Box';
import LanguageSelectorButton from './LanguageSelectorButton';
import LanguageSelectorList from './LanguageSelectorList';
import useViewLocales from './useViewLocales';

export type LanguageSelectorProps = {
  disabled?: boolean;
  variant?: 'default' | 'sidebar';
};

const LanguageSelector = ({ variant }: LanguageSelectorProps) => {
  const [open, setOpen] = React.useState(false);
  const viewLocales = useViewLocales();
  const ref = React.useRef<HTMLDivElement>(null);
  if (!viewLocales) return null;

  const { locales, currentLocale } = viewLocales;

  if (locales.length < 2) return null;

  return (
    <Box ref={ref}>
      <LanguageSelectorButton
        locale={currentLocale}
        onClick={() => setOpen(!open)}
        variant={variant === 'sidebar' ? 'default' : 'minimal'}
      />
      <Menu open={open} anchorEl={ref.current} onClose={() => setOpen(false)}>
        <LanguageSelectorList onClose={() => setOpen(false)} />
      </Menu>
    </Box>
  );
};

export default LanguageSelector;
