'use client';

import { View } from '@/lib/getters/getEntryByUri';
import { CommonLinks } from '@/lib/parsers/common';
import type { AppGlobals } from '@/lib/parsers/globals/globalsTypes';
import { createContext } from 'react';

export type AppContext = {
  view?: View | null;
  commonLinks?: CommonLinks;
  globals?: AppGlobals;
};

const initialState: AppContext = {};

export const appContext = createContext<AppContext>(initialState);

export const AppContextProviderInner = appContext.Provider;
