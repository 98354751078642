import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Btn from '../ui/Btn';
import Txt, { TxtProps } from '../ui/Txt';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardPhoneProps<D extends React.ElementType = 'p'> = TxtProps<
  D,
  {
    enableLinkWrap?: boolean;
  }
>;

export const CardPhone = <D extends React.ElementType>({
  children,
  enableLinkWrap = false,
  ...props
}: CardPhoneProps<D>) => {
  const { phone } = useCard();
  const content = children ?? phone;

  if (!content) return null;

  if (enableLinkWrap) {
    return (
      <Btn
        variant="text"
        color="neutral"
        as="a"
        href={'tel:' + content}
        {...mergePropsClassName(props, style.phone)}>
        {content}
      </Btn>
    );
  }

  return (
    <Txt as="span" color="neutralDark" {...mergePropsClassName(props, style.phone)}>
      {content}
    </Txt>
  );
};
